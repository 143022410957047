'use client';

import { XMarkIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import { removeItem } from 'components/cart/actions';
import LoadingDots from 'components/loading-dots';
import type { CartItem } from 'lib/shopify/types';
import { useFormState, useFormStatus } from 'react-dom';

function SubmitButton() {
  const { pending } = useFormStatus();

  return (
    <button
      type="submit"
      onClick={(e: React.FormEvent<HTMLButtonElement>) => {
        if (pending) e.preventDefault();
      }}
      aria-label="Remove cart item"
      aria-disabled={pending}
      className={clsx(
        'ease flex h-[17px] w-[17px] items-center justify-center rounded-full bg-neutral-500 transition-all duration-200',
        {
          'cursor-not-allowed px-0': pending
        }
      )}
    >
      {pending ? (
        <LoadingDots className="bg-white" />
      ) : (
        <XMarkIcon className="hover:text-accent-3 mx-[1px] h-4 w-4 text-white dark:text-black" />
      )}
    </button>
  );
}

export function DeleteItemButton({ item }: { item: CartItem }) {
  const [message, formAction] = useFormState(removeItem, null);
  const itemId = item.id;
  const actionWithVariant = formAction.bind(null, itemId);

  return (
    <form action={actionWithVariant}>
      <SubmitButton />
      <p aria-live="polite" className="sr-only" role="status">
        {message}
      </p>
    </form>
  );
}




//import { XMarkIcon } from '@heroicons/react/24/outline';
//import LoadingDots from 'components/loading-dots';
//import { useRouter } from 'next/navigation';
//
//import clsx from 'clsx';
//import { removeItem } from 'components/cart/actions';
//import type { CartItem } from 'lib/shopify/types';
//import { useTransition } from 'react';
//
//export default function DeleteItemButton({ item }: { item: CartItem }) {
//  const router = useRouter();
//  const [isPending, startTransition] = useTransition();
//
//  return (
//    <button
//      aria-label="Remove cart item"
//      onClick={() => {
//        startTransition(async () => {
//          const error = await removeItem(item.id);
//
//          if (error) {
//            // Trigger the error boundary in the root error.js
//            throw new Error(error.toString());
//          }
//
//          router.refresh();
//        });
//      }}
//      disabled={isPending}
//      className={clsx(
//        'ease flex h-[17px] w-[17px] items-center justify-center rounded-full bg-neutral-500 transition-all duration-200',
//        {
//          'cursor-not-allowed px-0': isPending
//        }
//      )}
//    >
//      {isPending ? (
//        <LoadingDots className="bg-white" />
//      ) : (
//        <XMarkIcon className="hover:text-accent-3 mx-[1px] h-4 w-4 text-white dark:text-black" />
//      )}
//    </button>
//  );
//}
//