import { clsx, type ClassValue } from "clsx";
import { customAlphabet } from "nanoid";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const nanoid = customAlphabet(
  "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz",
  10
); // random string

export const asJson = (res) => res.json();
export const asJpg = (url) => `https://wsrv.nl/?url=${encodeURIComponent(url)}&output=jpg&q=96`;
export const asWebp = ({url, q=90, xtra=''}) => `https://wsrv.nl/?url=${encodeURIComponent(url)}&output=webp&q=${q}${xtra}`;

export async function fetcher<JSON = any>(input: RequestInfo, init?: RequestInit): Promise<JSON> {
  const res = await fetch(input, init);

  if (!res.ok) {
    const json = await res.json();
    if (json.error) {
      const error = new Error(json.error) as Error & {
        status: number;
      };
      error.status = res.status;
      throw error;
    } else {
      throw new Error("An unexpected error occurred");
    }
  }

  return res.json();
}

export async function asB64({ url, response }) {
  if (url) {
    response = await fetch(url, { cache: "no-store" });
  }
  const buffer = await response.arrayBuffer();
  const mimeType = response.headers.get("content-type");
  const b64 = Buffer.from(buffer).toString("base64");
  return `data:${mimeType};base64,${b64}`;
}

// export async function toJpg(url) {
//   const response = await fetch(url);
//   const buffer = await response.arrayBuffer();
//   const jpgBuffer = await sharp(Buffer.from(buffer)).jpeg().toBuffer();
//   const b64 = jpgBuffer.toString('base64');
//   return `data:image/jpeg;base64,${b64}`
// }

export function formatDate(input: string | number | Date): string {
  const date = new Date(input);
  return date.toLocaleDateString("en-US", {
    month: "long",
    day: "numeric",
    year: "numeric"
  });
}

import { ReadonlyURLSearchParams } from "next/navigation";

export const createUrl = (pathname: string, params: URLSearchParams | ReadonlyURLSearchParams) => {
  const paramsString = params.toString();
  const queryString = `${paramsString.length ? "?" : ""}${paramsString}`;

  return `${pathname}${queryString}`;
};

export const setSearchParams = (params, router, pathname) => {
  const url = createUrl(pathname, params);
  router.replace(url, { scroll: false });
}


export function mergeSearchParams(params1: URLSearchParams, params2: URLSearchParams): URLSearchParams {
  const merged = new URLSearchParams(params1.toString());
  for (const [key, value] of params2) {
    merged.set(key, value);
  }
  return merged;
}


// import { Product } from 'lib/shopify/types';
// import { usePathname, useRouter, useSearchParams } from 'next/navigation';


// export const useOptionState = (product: Product) => {
//   const router = useRouter();
//   const searchParams = useSearchParams();
//   const pathname = usePathname();

//   const { options, variants } = product || {}

//   if (!variants?.length || !options?.length) {
//     return {
//       options: [],
//       variants: [],
//       activeVariant: null
//     };
//   }

//   const combinations = variants.map((variant) => ({
//     id: variant.id,
//     availableForSale: variant.availableForSale,
//     // Adds key / value pairs for each variant (ie. "color": "Black" and "size": 'M").
//     ...(variant.selectedOptions?.reduce(
//       (accumulator, option) => ({ ...accumulator, [option.name.toLowerCase()]: option.value }),
//       {}
//     ) || {})
//   }));

//   const optionsWithState = options.map((option) => {

//     return {
//       ...option,
//       state: option.values.map((value) => {

//         const optionNameLowerCase = option.name.toLowerCase();

//         // Base option params on current params so we can preserve any other param state in the url.
//         const optionSearchParams = new URLSearchParams(searchParams.toString());

//         // Update the option params using the current option to reflect how the url *would* change,
//         // if the option was clicked.
//         optionSearchParams.set(optionNameLowerCase, value);

//         // In order to determine if an option is available for sale, we need to:
//         //
//         // 1. Filter out all other param state
//         // 2. Filter out invalid options
//         // 3. Check if the option combination is available for sale
//         //
//         // This is the "magic" that will cross check possible variant combinations and preemptively
//         // disable combinations that are not available. For example, if the color gray is only available in size medium,
//         // then all other sizes should be disabled.
//         const filtered = Array.from(optionSearchParams.entries()).filter(([key, value]) =>
//           options.find(
//             (option) => option.name.toLowerCase() === key && option.values.includes(value)
//           )
//         );
//         const isAvailableForSale = combinations.find((combination) =>
//           filtered.every(
//             ([key, value]) => combination[key] === value && combination.availableForSale
//           )
//         );

//         // The option is active if it's in the url params.
//         const isActive = searchParams.get(optionNameLowerCase) === value;

//         return {
//           isActive,
//           isAvailableForSale,
//           activate: () => router.replace(createUrl(pathname, optionSearchParams))
//         };
//       })
//     };
//   });

//   const variantsWithState = variants.map((variant) => {
//     const isActive = variant.selectedOptions.every((option) =>
//       optionsWithState.find(
//         (optionState) =>
//           optionState.name.toLowerCase() === option.name.toLowerCase() &&
//           optionState.state.find(
//             (valueState) =>
//               valueState.isActive && valueState.value === option.value
//           )
//       )
//     );

//     return {
//       ...variant,
//       isActive,
//       activate: () => {
//         const variantSearchParams = new URLSearchParams(searchParams.toString());
//         variant.selectedOptions.forEach((option) => {
//           variantSearchParams.set(option.name.toLowerCase(), option.value);
//         });
//         router.replace(createUrl(pathname, variantSearchParams));
//       }
//     };
//   });

//   return {
//     options: optionsWithState,
//     variants: variantsWithState,
//     activeVariant: variantsWithState.find((variant) => variant.isActive)
//   }
// };

export const ensureStartsWith = (stringToCheck: string, startsWith: string) =>
  stringToCheck.startsWith(startsWith) ? stringToCheck : `${startsWith}${stringToCheck}`;

export function stringToSeed(s, tableSize = 4294967294) {
  let hash = 0;
  for (let i = 0; i < s.length; i++) {
    hash = (hash + s.charCodeAt(i) * i) % tableSize;
  }
  return hash;
}

export function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export const randNum = () => (Math.random() + "").slice(2);

// https://blog.openreplay.com/forever-functional-waiting-with-promises/
export const untilAsync = async (fn, wait = 30000, time = 1000) => {
  const startTime = new Date().getTime(); /* [1] */
  let result;
  for (;;) {
    /* [2] */
    try {
      result = await fn();
      if (result) {
        /* [3] */
        return result;
      }
    } catch (e) {
      /* [4] */
      throw e;
    }

    if (new Date().getTime() - startTime > wait) {
      throw new Error("Max wait reached"); /* [5] */
    } else {
      /* [6] */
      await new Promise((resolve) => setTimeout(resolve, time));
    }
  }
};

export const until = (fn, wait = 30000, time = 1000) => {
  const startTime = new Date().getTime(); /* [1] */
  let result;
  try {
    result = fn();
    if (result) {
      return Promise.resolve(result);
    } else {
      return new Promise((resolve, reject) => {
        const timer = setInterval(() => {
          try {
            result = fn();
            if (result) {
              clearInterval(timer);
              resolve(result);
            } else if (new Date().getTime() - startTime > wait) {
              clearInterval(timer); /* [2] */
              reject(new Error("Max wait reached")); /* [3] */
            }
          } catch (e) {
            clearInterval(timer);
            reject(e);
          }
        }, time);
      });
    }
  } catch (e) {
    return Promise.reject(e);
  }
};

export const untilImg = (url, ivl = 500, max = 60000) => {
  return untilAsync(
    async () => {
      let response;
      try {
        response = await fetch(url, { cache: "no-store" });
        // response = await fetch(url);
        const contentLength = response.headers.get("content-length");

        if (parseInt(contentLength) > 10000) {
          return response;
        }
      } catch (e) {
        console.log(e);
      }

      return false;
    },
    ivl,
    max
  );
};

// The printify OpenAPI spec which was converted from postman had
// a field called 'parameters' that should have been nested in each action,
// but was instead inside the whole paths object. This function fixes that.
// (OpenAI did not accept the spec as-is)
const fixOas = (oa) => {
  const fixedPaths = Object.entries(oa.paths).map((k, v) => {
    const p = v.parameters;
    if (p) {
      ["get", "put", "post", "delete"].forEach((m) => {
        if (v[m]) {
          v[m].parameters = v.parameters;
        }
      });
    }
    console.log(p);
    delete v.parameters;
    return [k, v];
  });
};

// export async function fetchImgWhenReady({src, wait = 1000, tries = 15}) {
//     let response, buffer, b64;

//     while (tries--) {
//         try {
//             response = await fetch(src, {cache: 'no-store'});
//             buffer = await response.arrayBuffer();
//             b64 = Buffer.from(buffer).toString("base64")
//             if (response.ok && b64.length > 10000) break;
//             await sleep(wait);
//         } catch (e) {
//             console.log(e)
//         }
//     }

//     const finalSrc = b64.length > 10000 ? `data:image/jpeg;base64,${b64}` : src;

//     return finalSrc;
// }

export const validateEnvironmentVariables = () => {
  const requiredEnvironmentVariables = ["SHOPIFY_STORE_DOMAIN", "SHOPIFY_STOREFRONT_ACCESS_TOKEN"];
  const missingEnvironmentVariables = [] as string[];

  requiredEnvironmentVariables.forEach((envVar) => {
    if (!process.env[envVar]) {
      missingEnvironmentVariables.push(envVar);
    }
  });

  if (missingEnvironmentVariables.length) {
    throw new Error(
      `The following environment variables are missing. Your site will not work without them. Read more: https://vercel.com/docs/integrations/shopify#configure-environment-variables\n\n${missingEnvironmentVariables.join(
        "\n"
      )}\n`
    );
  }

  if (
    process.env.SHOPIFY_STORE_DOMAIN?.includes("[") ||
    process.env.SHOPIFY_STORE_DOMAIN?.includes("]")
  ) {
    throw new Error(
      "Your `SHOPIFY_STORE_DOMAIN` environment variable includes brackets (ie. `[` and / or `]`). Your site will not work with them there. Please remove them."
    );
  }
};
