'use server';

import { TAGS } from 'lib/constants';
import { addToCart, createCart, getCart, removeFromCart, updateCart } from 'lib/shopify';
import { revalidateTag } from 'next/cache';
import { cookies } from 'next/headers';

export async function addItem(prevState: any, selectedVariantId: string | undefined) {
  let cartId = cookies().get('cartId')?.value;
  let cart;

  if (cartId) {
    cart = await getCart(cartId);
  }

  if (!cartId || !cart) {
    cart = await createCart();
    cartId = cart.id;
    cookies().set('cartId', cartId);
  }

  if (!selectedVariantId) {
    return 'Missing product variant ID';
  }

  try {
    await addToCart(cartId, [{ merchandiseId: selectedVariantId, quantity: 1 }]);
    revalidateTag(TAGS.cart);
  } catch (e) {
    return 'Error adding item to cart';
  }
}

export async function removeItem(prevState: any, lineId: string) {
  const cartId = cookies().get('cartId')?.value;

  if (!cartId) {
    return 'Missing cart ID';
  }

  try {
    await removeFromCart(cartId, [lineId]);
    revalidateTag(TAGS.cart);
  } catch (e) {
    return 'Error removing item from cart';
  }
}

export async function updateItemQuantity(
  prevState: any,
  payload: {
    lineId: string;
    variantId: string;
    quantity: number;
  }
) {
  const cartId = cookies().get('cartId')?.value;

  if (!cartId) {
    return 'Missing cart ID';
  }

  const { lineId, variantId, quantity } = payload;

  try {
    if (quantity === 0) {
      await removeFromCart(cartId, [lineId]);
      revalidateTag(TAGS.cart);
      return;
    }

    await updateCart(cartId, [
      {
        id: lineId,
        merchandiseId: variantId,
        quantity
      }
    ]);
    revalidateTag(TAGS.cart);
  } catch (e) {
    return 'Error updating item quantity';
  }
}




//'use server';
//
//import { addToCart, createCart, getCart, removeFromCart, updateCart } from 'lib/shopify';
//import { cookies } from 'next/headers';
//
//export const addItem = async (variantId: string | undefined): Promise<String | undefined> => {
//  let cartId = cookies().get('cartId')?.value;
//  let cart;
//
//  if (cartId) {
//    cart = await getCart(cartId);
//  }
//
//  if (!cartId || !cart) {
//    cart = await createCart();
//    cartId = cart.id;
//    if (cartId) cookies().set('cartId', cartId);
//  }
//
//  if (!variantId) {
//    return 'Missing product variant ID';
//  }
//
//  if (!cartId) {
//    return 'Missing cart ID';
//  }
//
//  try {
//    await addToCart(cartId, [{ merchandiseId: variantId, quantity: 1 }]);
//  } catch (e) {
//    return 'Error adding item to cart';
//  }
//};
//
//export const removeItem = async (lineId: string): Promise<String | undefined> => {
//  const cartId = cookies().get('cartId')?.value;
//
//  if (!cartId) {
//    return 'Missing cart ID';
//  }
//  try {
//    await removeFromCart(cartId, [lineId]);
//  } catch (e) {
//    return 'Error removing item from cart';
//  }
//};
//
//export const updateItemQuantity = async ({
//  lineId,
//  variantId,
//  quantity
//}: {
//  lineId: string;
//  variantId: string;
//  quantity: number;
//}): Promise<String | undefined> => {
//  const cartId = cookies().get('cartId')?.value;
//
//  if (!cartId) {
//    return 'Missing cart ID';
//  }
//  try {
//    await updateCart(cartId, [
//      {
//        id: lineId,
//        merchandiseId: variantId,
//        quantity
//      }
//    ]);
//  } catch (e) {
//    return 'Error updating item quantity';
//  }
//};
//