'use client';

import { MinusIcon, PlusIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import { updateItemQuantity } from 'components/cart/actions';
import LoadingDots from 'components/loading-dots';
import type { CartItem } from 'lib/shopify/types';
import { useFormState, useFormStatus } from 'react-dom';

function SubmitButton({ type }: { type: 'plus' | 'minus' }) {
  const { pending } = useFormStatus();

  return (
    <button
      type="submit"
      onClick={(e: React.FormEvent<HTMLButtonElement>) => {
        if (pending) e.preventDefault();
      }}
      aria-label={type === 'plus' ? 'Increase item quantity' : 'Reduce item quantity'}
      aria-disabled={pending}
      className={clsx(
        'ease flex h-full min-w-[36px] max-w-[36px] flex-none items-center justify-center rounded-full px-2 transition-all duration-200 hover:border-neutral-800 hover:opacity-80',
        {
          'cursor-not-allowed': pending,
          'ml-auto': type === 'minus'
        }
      )}
    >
      {pending ? (
        <LoadingDots className="bg-black dark:bg-white" />
      ) : type === 'plus' ? (
        <PlusIcon className="h-4 w-4 dark:text-neutral-500" />
      ) : (
        <MinusIcon className="h-4 w-4 dark:text-neutral-500" />
      )}
    </button>
  );
}

export function EditItemQuantityButton({ item, type }: { item: CartItem; type: 'plus' | 'minus' }) {
  const [message, formAction] = useFormState(updateItemQuantity, null);
  const payload = {
    lineId: item.id,
    variantId: item.merchandise.id,
    quantity: type === 'plus' ? item.quantity + 1 : item.quantity - 1
  };
  const actionWithVariant = formAction.bind(null, payload);

  return (
    <form action={actionWithVariant}>
      <SubmitButton type={type} />
      <p aria-live="polite" className="sr-only" role="status">
        {message}
      </p>
    </form>
  );
}





//import { useRouter } from 'next/navigation';
//import { useTransition } from 'react';
//
//import { MinusIcon, PlusIcon } from '@heroicons/react/24/outline';
//import clsx from 'clsx';
//import { removeItem, updateItemQuantity } from 'components/cart/actions';
//import LoadingDots from 'components/loading-dots';
//import type { CartItem } from 'lib/shopify/types';
//
//export default function EditItemQuantityButton({
//  item,
//  type
//}: {
//  item: CartItem;
//  type: 'plus' | 'minus';
//}) {
//  const router = useRouter();
//  const [isPending, startTransition] = useTransition();
//
//  return (
//    <button
//      aria-label={type === 'plus' ? 'Increase item quantity' : 'Reduce item quantity'}
//      onClick={() => {
//        startTransition(async () => {
//          const error =
//            type === 'minus' && item.quantity - 1 === 0
//              ? await removeItem(item.id)
//              : await updateItemQuantity({
//                  lineId: item.id,
//                  variantId: item.merchandise.id,
//                  quantity: type === 'plus' ? item.quantity + 1 : item.quantity - 1
//                });
//
//          if (error) {
//            // Trigger the error boundary in the root error.js
//            throw new Error(error.toString());
//          }
//
//          router.refresh();
//        });
//      }}
//      disabled={isPending}
//      className={clsx(
//        'ease flex h-full min-w-[36px] max-w-[36px] flex-none items-center justify-center rounded-full px-2 transition-all duration-200 hover:border-neutral-800 hover:opacity-80',
//        {
//          'cursor-not-allowed': isPending,
//          'ml-auto': type === 'minus'
//        }
//      )}
//    >
//      {isPending ? (
//        <LoadingDots className="bg-black dark:bg-white" />
//      ) : type === 'plus' ? (
//        <PlusIcon className="h-4 w-4 dark:text-neutral-500" />
//      ) : (
//        <MinusIcon className="h-4 w-4 dark:text-neutral-500" />
//      )}
//    </button>
//  );
//}
//