"use client";
// app/providers.tsx


// import dynamic from "next/dynamic";
// const IoProvider = dynamic(() => import("../components/providers/io-provider"), { ssr: false });

import { EcosystemProvider, createEcosystem } from "@zedux/react";

const ecosystem = createEcosystem({
  id: 'root',
  ssr: true
});

import { ThemeProvider } from "next-themes";

export function Providers({ children, ...props }: { children: React.ReactNode }) {
  return (
    <EcosystemProvider ecosystem={ecosystem}>
      <ThemeProvider {...props}>
        {/* <IoProvider>{children}</IoProvider> */}
        {children}
      </ThemeProvider>
    </EcosystemProvider>
  );
}
